import styled from "styled-components"

export const FluidContainer = styled.div`
  box-sizing: border-box;
  flex: 1 1 auto;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 ${p => p.theme.outerMargin};
  @media (max-width: ${props => props.theme.screen.mobile}) {
    padding: 0 ${p => p.theme.gutterWidth};
  }
`

export const ContainerWide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: auto;
  max-width: ${p => p.theme.containers.lg};
  @media (max-width: ${props => props.theme.screen.medium}) {
    max-width: 100%;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: auto;
  max-width: ${props => props.theme.containers.md};
`

export const ContainerSpaceBetween = styled(Container)`
  flex-direction: row;
  flex: 1 1 100%;
  justify-content: space-between;
  align-items: center;
`

export const ContainerSmall = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: ${p => p.theme.containers.sm};
`

export const ContainerTiny = styled.div`
  display: flex;
  flex: auto;
  flex: 1 0 auto;
  align-self: center;
  max-width: ${p => p.theme.containers.xs};
`

export const Background = styled.div`
  width: 100%;
  background: ${p => (p.color ? p.theme.colors[p.color] : "transparent")};
`
