import styled from "styled-components"

export const MainTitle = styled.h1`
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.grey};
  margin-bottom: ${props =>
    props.margin ? props.theme.margins[props.margin] : 0};
  margin-right: ${props =>
    !!props.marginRight ? props.theme.margins[props.marginRight] : 0};
  display: ${props => (props.inline ? "inline" : "block")};
  font-weight: ${props => {
    if (props.black) return props.theme.weights.black
    if (props.superbold) return props.theme.weights.superbold
    if (props.bold) return props.theme.weights.bold
    if (props.semibold) return props.theme.weights.semibold
    if (props.thin) return props.theme.weights.thin
    return props.theme.weights.regular
  }};
  font-size: ${props =>
    !!props.size
      ? props.theme.fontSizes[props.size]
      : props.theme.fontSizes.lg};
  text-transform: ${props => {
    if (props.capitalize) return "capitalize"
    if (props.uppercase) return "uppercase"
    return "none"
  }};
  font-family: ${props =>
    props.serif ? props.theme.fonts.serif : props.theme.fonts.sansSerif};
  line-height: ${props => (!!props.lineHeight ? props.lineHeight : 1.25)};
  text-align: ${props => {
    if (props.center) return "center"
    if (props.right) return "right"
    if (props.left) return "left"
    return "auto"
  }};
  text-shadow: ${props => (props.shadow ? "0 0 10px rgba(0,0,0,1)" : "none")};
  padding: 0;
  letter-spacing: ${props => (props.uppercase ? "0.05em" : 0)};
  white-space: ${props => (props.noWrap ? "nowrap" : "default")};
  cursor: ${props => (props.clickable ? "pointer" : "default")};
  z-index: auto;
  &:hover {
    text-decoration: ${props => (props.clickable ? "underline" : "none")};
  }
`

export const Title = MainTitle.withComponent("h2")
export const Subtitle = MainTitle.withComponent("h3")

export const Text = styled.p`
  line-height: ${props => (!!props.lineHeight ? props.lineHeight : 1.5)};
  font-size: ${props =>
    !!props.size
      ? props.theme.fontSizes[props.size]
      : props.theme.fontSizes.nm};
  font-weight: ${props =>
    props.bold ? props.theme.weights.bold : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.text};

  margin-bottom: ${p => (p.margin ? p.theme.margins[p.margin] : "20px")};
  cursor: ${props => (props.clickable ? "pointer" : "default")};

  &:first-of-type {
    padding-top: 0;
  }
`

export const Span = styled.span`
  font-size: ${props =>
    props.size ? props.theme.fontSizes[props.size] : props.theme.fontSizes.nm};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.text};
  background: ${props =>
    props.background ? props.theme.colors[props.background] : "transparent"};
  font-weight: ${props => {
    if (props.normal) return props.theme.weights.normal
    if (props.bold) return props.theme.weights.bold
    if (props.black) return props.theme.weights.black
    return props.theme.weights.normal
  }} !important;
  margin-bottom: ${props =>
    props.margin ? props.theme.margins[props.margin] : 0};
  margin-right: ${props =>
    props.marginRight ? props.theme.margins[props.marginRight] : 0};
  padding: ${props => (props.padding ? props.theme.margins[props.padding] : 0)};
  opacity: ${props => (props.opacity ? props.opacity : 1)};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 1.5)};
  text-align: ${props => (props.center ? "center" : "auto")};
  white-space: ${props => (props.nowrap ? "nowrap" : "normal")};
  cursor: ${props => (props.clickable ? "pointer" : "default")};
  &:hover {
    text-decoration: ${props => (props.clickable ? "underline" : "none")};
  }
`
