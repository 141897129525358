import React from "react"
import styled from "styled-components"
import { Title, Subtitle } from "../base/Text"

const NewsletterWrapper = styled.div`
  flex: 1 1 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: ${props => props.theme.margins.xl};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    form {
      width: 100%;
    }
  }
`

const NewsletterForm = styled.form`
  flex: 1 1 100%;
  display: flex;
  align-self: center;
  @media (max-width: ${p => p.theme.screen.mobile}) {
    flex-direction: column;
  }
`

const NewsletterField = styled.input`
  flex: 1 1 30%;
  color: ${props => props.theme.colors.primary300};
  background: ${props => props.theme.colors.primary900};
  border: 2px solid ${props => props.theme.colors.primary500};
  padding: 0 ${props => props.theme.margins.md};
  margin-right: ${props => props.theme.margins.sm};
  font-size: ${props => props.theme.fontSizes.nm};
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  outline: none;
  transition: all 0.2s ease-in-out;
  &::placeholder {
    color: ${props => props.theme.colors.primary600};
  }
  &:focus {
    border: 2px solid ${props => props.theme.colors.primary300};
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    min-width: 300px;
    margin-right: 0;
    border-radius: 0;
    margin-bottom: ${props => props.theme.margins.nm};
  }
  &:first-of-type {
    border-top-left-radius: ${props => props.theme.radius.lg};
    border-bottom-left-radius: ${props => props.theme.radius.lg};
    @media (max-width: ${p => p.theme.screen.mobile}) {
      border-radius: 0;
      border-top-left-radius: ${props => props.theme.radius.lg};
      border-top-right-radius: ${props => props.theme.radius.lg};
    }
  }
`

const SubscribeButton = styled.button`
  padding: ${props => props.theme.margins.md} ${props => props.theme.margins.xl};
  color: ${props => props.theme.colors.primary200};
  background: ${props => props.theme.colors.primary600};
  border-top-right-radius: ${props => props.theme.radius.lg};
  border-bottom-right-radius: ${props => props.theme.radius.lg};
  font-size: ${props => props.theme.fontSizes.nm};
  height: 50px;
  outline: 0;
  border: 0;
  &:hover {
    background: ${props => props.theme.colors.primary500};
    color: ${props => props.theme.colors.primary150};
  }
  &:active {
    background: ${props => props.theme.colors.primary400};
    color: ${props => props.theme.colors.primary100};
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    width: 100%;
    min-width: 300px;
    border-radius: 0;
    border-bottom-left-radius: ${props => props.theme.radius.lg};
    border-bottom-right-radius: ${props => props.theme.radius.lg};
  }
`

const Subscribe = () => (
  <NewsletterWrapper>
    <Title size="lg" color="primary100" margin="sm" black>
      Let's learn together and keep your career moving
    </Title>
    <Subtitle size="nm" color="primary150" margin="lg">
      Subscribe and stay up to date with what's happening
    </Subtitle>
    <NewsletterForm
      action="https://gmail.us10.list-manage.com/subscribe/post?u=ad72e9706ca16886b52c76e66&amp;id=e461cc14a6"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      novalidate
    >
      <NewsletterField
        type="text"
        placeholder="Your first name"
        name="FNAME"
        id="mce-FNAME"
      />
      <NewsletterField
        type="email"
        placeholder="Your email"
        name="EMAIL"
        className="required email"
        id="mce-EMAIL"
      />
      <SubscribeButton
        type="submit"
        value="Subscribe"
        name="subscribe"
        id="mc-embedded-subscribe"
      >
        Subscribe
      </SubscribeButton>
    </NewsletterForm>
  </NewsletterWrapper>
)

export default Subscribe
