import React from "react"
import styled from "styled-components"
import { Title, Subtitle } from "../base/Text"
import { FluidContainer, Container } from "../base/Container"

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
  min-width: 100%;
  padding: ${props => props.theme.margins.lg};
  background: ${props => props.theme.colors.primary500};
  transition: all 0.15s ease-out;
  z-index: 10000;
`

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
`

const ApproveButton = styled.button`
  outline: none;
  border: 0;
  padding: ${props => props.theme.margins.md} ${props => props.theme.margins.xl};
  color: ${props => props.theme.colors.primary100};
  font-size: ${props => props.theme.fontSizes.nm};
  background: ${props => props.theme.colors.primary700};
`

const Cookies = ({ onAccept }) => {
  return (
    <Wrapper>
      <FluidContainer>
        <Container>
          <Title size="nm" color="primary100" margin="md" bold center>
            We use cookies
          </Title>
          <Subtitle size="sm" margin="lg" color="primary200" center>
            Codecamps uses cookies on this website, to collect information on
            the website traffic (Google Analytics). You agree to our use of
            cookies by clicking "Got It!" or continuing to use the Codecamps'
            website.
          </Subtitle>
          <ButtonsRow>
            <ApproveButton onClick={onAccept}>Got it!</ApproveButton>
          </ButtonsRow>
        </Container>
      </FluidContainer>
    </Wrapper>
  )
}

export default Cookies
