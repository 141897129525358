import React from "react"
import { Title } from "../base/Text"
import { Link } from "gatsby"

const Logo = () => (
  <Link to="/">
    <Title size="lg" color="primary500" black>
      Codecamps
    </Title>
  </Link>
)

export default Logo
