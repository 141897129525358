import React from "react"
import styled from "styled-components"

import Logo from "./Logo"
import Menu from "./Menu"

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 ${props => props.theme.outerMargin};
  background: ${props => props.theme.colors.primary800};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    padding: 0 ${props => props.theme.gutterWidth};
  }
`

const HeaderContainer = styled.div`
  height: 60px;
  flex: 1 1 100%;
  max-width: ${props => props.theme.containers.lg};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Header = ({ pathname }) => (
  <HeaderWrapper>
    <HeaderContainer>
      <Logo />
      <Menu pathname={pathname} />
    </HeaderContainer>
  </HeaderWrapper>
)

export default Header
