import { createGlobalStyle } from "styled-components"
import Theme from "./base/Theme"

const GlobalStyle = createGlobalStyle`
   @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,900');
  *, *:focus {
    outline: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
    @media (max-width: ${Theme.screen.medium}) {
      font-size: 15px;
    }
    @media (max-width: ${Theme.screen.mobile}) {
      font-size: 14px;
    }
  }
  html,
  body,
  div,
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    font-family: "Lato", sans-serif;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  p {
    line-height: 1.55;
  }

  a {
    text-decoration: none;
    color: #5198ec;
  }
`

export default GlobalStyle
