const gutterWidth = 1 // rem
const outerMargin = 2 // rem

const containers = {
  xs: "500px",
  sm: "700px",
  md: "1000px",
  lg: "1200px",
}
const breakPoints = {
  mobile: "600",
  medium: "1000",
  large: "1200",
}

const screen = {
  mobile: `${breakPoints.mobile}px`,
  medium: `${breakPoints.medium}px`,
  large: `${breakPoints.large}px`,
}

const weights = {
  thin: "400",
  regular: "400",
  semibold: "400",
  bold: "700",
  black: "800",
}

const baseColors = {
  white: "#ffffff",

  grey900: "hsl(217, 26%, 16%)",
  grey800: "hsl(217, 22%, 31%)",
  grey700: "hsl(217, 18%, 40%)",
  grey600: "hsl(217, 18%, 48%)",
  grey500: "hsl(217, 18%, 56%)",
  grey400: "hsl(217, 22%, 69%)",
  grey300: "hsl(217, 22%, 82%)",
  grey200: "hsl(217, 24%, 92%)",
  grey150: "hsl(217, 27%, 95%)",
  grey100: "hsl(217, 30%, 97%)",

  primary900: "hsl(225, 100%, 7%)",
  primary800: "hsl(223, 100%, 12%)",
  primary700: "hsl(222, 98%, 20%)",
  primary650: "hsl(222, 99%, 30%)",
  primary600: "hsl(222, 98%, 40%)",
  primary550: "hsl(220, 98%, 45%)",
  primary500: "hsl(219, 97%, 50%)",
  primary400: "hsl(220, 97%, 60%)",
  primary300: "hsl(221, 99%, 75%)",
  primary200: "hsl(221, 100%, 85%)",
  primary150: "hsl(222, 100%, 92%)",
  primary100: "hsl(222, 100%, 97%)",

  secondary900: "hsl(16, 90%, 16%)",
  secondary800: "hsl(14, 80%, 24%)",
  secondary700: "hsl(12, 73%, 32%)",
  secondary600: "hsl(10, 73%, 40%)",
  secondary500: "hsl(8, 67%, 50%)",
  secondary400: "hsl(10, 70%, 60%)",
  secondary300: "hsl(12, 73%, 75%)",
  secondary200: "hsl(14, 80%, 85%)",
  secondary100: "hsl(16, 90%, 97%)",

  tertiary900: "hsl(39, 95%, 16%)",
  tertiary800: "hsl(41, 92%, 24%)",
  tertiary700: "hsl(44, 89%, 32%)",
  tertiary600: "hsl(45, 87%, 40%)",
  tertiary500: "hsl(47, 87%, 50%)",
  tertiary400: "hsl(48, 87%, 60%)",
  tertiary300: "hsl(49, 89%, 75%)",
  tertiary200: "hsl(50, 92%, 85%)",
  tertiary150: "hsl(50, 93%, 91%)",
  tertiary100: "hsl(51, 95%, 97%)",

  fourth900: "hsl(151, 65%, 16%)",
  fourth800: "hsl(151, 62%, 24%)",
  fourth700: "hsl(151, 69%, 32%)",
  fourth600: "hsl(151, 67%, 40%)",
  fourth500: "hsl(151, 64%, 50%)",
  fourth400: "hsl(151, 67%, 60%)",
  fourth300: "hsl(151, 69%, 75%)",
  fourth200: "hsl(151, 62%, 85%)",
  fourth150: "hsl(151, 62%, 91%)",
  fourth100: "hsl(151, 62%, 97%)",

  vue900: "hsl(151, 60%, 4%)",
  vue800: "hsl(151, 67%, 12%)",
  vue700: "hsl(151, 67%, 20%)",
  vue600: "hsl(151, 66%, 30%)",
  vue500: "hsl(146, 65%, 47%)",
  vue400: "hsl(151, 65%, 50%)",
  vue300: "hsl(151, 63%, 70%)",
  vue200: "hsl(151, 63%, 85%)",
  vue150: "hsl(151, 62%, 91%)",
  vue100: "hsl(151, 61%, 97%)",

  angular900: "hsl(225, 100%, 7%)",
  angular800: "hsl(223, 100%, 12%)",
  angular700: "hsl(222, 98%, 20%)",
  angular650: "hsl(222, 99%, 30%)",
  angular600: "hsl(222, 98%, 40%)",
  angular550: "hsl(220, 98%, 45%)",
  angular500: "hsl(219, 97%, 50%)",
  angular400: "hsl(220, 97%, 60%)",
  angular300: "hsl(221, 99%, 75%)",
  angular200: "hsl(221, 100%, 85%)",
  angular150: "hsl(222, 100%, 92%)",
  angular100: "hsl(222, 100%, 97%)",

  // angular900: "hsl(350, 100%, 7%)",
  // angular800: "hsl(349, 100%, 12%)",
  // angular700: "hsl(349, 98%, 20%)",
  // angular650: "hsl(349, 99%, 30%)",
  // angular600: "hsl(349, 98%, 40%)",
  // angular550: "hsl(348, 98%, 45%)",
  // angular500: "hsl(347, 97%, 50%)",
  // angular400: "hsl(348, 97%, 60%)",
  // angular300: "hsl(349, 99%, 75%)",
  // angular200: "hsl(350, 100%, 85%)",
  // angular150: "hsl(350, 100%, 92%)",
  // angular100: "hsl(350, 100%, 97%)",

  react900: "hsl(205, 100%, 7%)",
  react800: "hsl(204, 100%, 12%)",
  react700: "hsl(203, 98%, 20%)",
  react650: "hsl(202, 99%, 30%)",
  react600: "hsl(200, 98%, 40%)",
  react550: "hsl(200, 98%, 45%)",
  react500: "hsl(201, 97%, 50%)",
  react400: "hsl(203, 97%, 60%)",
  react300: "hsl(205, 99%, 75%)",
  react200: "hsl(207, 100%, 85%)",
  react150: "hsl(207, 100%, 92%)",
  react100: "hsl(207, 100%, 97%)",

  transparent75: "rgba(255, 255, 255, 0.75)",

  accent: "#C3002F",
  accentHover: "#DD0031",

  purple: "#8e40f8",

  lightGrey: "#aaa",
  darkGrey: "#777777",
  veryLightGrey: "#e5e5e5",
}

const colors = {
  ...baseColors,
  angularHover: "hsl(343, 0.79, 0.53)",
  angularAction: "hsl(343, 0.79, 0.57)",

  originalReact: "hsl(192, 1, 0.46)",
  reactHover: "hsl(192, 1, 0.5)",
  reactAction: "hsl(192, 1, 0.54)",
  reactLogo: "#61dafb",

  angular: "#DD0031",

  vue: "hsl(146, 75%, 47%)",
  react: "hsl(201, 97%, 50%)",
  vueHover: "#34495E",

  meteor: " hsl(327, 1, 0.41)",
  meteorHover: "hsl(327, 1, 0.45)",
  meteorAction: "hsl(327, 1, 0.49)",

  twitter: "#55acee",
  facebook: "#568bfc",
  linkedin: "#0077B5",
  instagram: "#f9c715",
  mail: "#f93e15",
  checkout: "hsl(100, 0.75, 0.61)",
  checkoutHover: "hsl(100, 0.75, 0.66)",
  checkoutAction: "hsl(100, 0.75, 0.69)",

  graphql: "#e33caa",
}

const headerColors = {
  backgroundColor: colors.text,
  borderColor: colors.textAccent,
}

const menuColors = {
  itemColor: "rgba(255, 255, 255, 0.75)",
  itemColorHover: "rgba(255, 255, 255, 0.9)",
}

const margins = {
  xxxl: "89px",
  xxl: "55px",
  xl: "34px",
  lg: "21px",
  md: "13px",
  nm: "8px",
  sm: "5px",
  0: "0",
  gutter: `${gutterWidth}rem`,
  outer: `${outerMargin}rem`,
}

const fonts = {
  sansSerif: "'Muli', 'Arial', sans-serif",
  serif: "'Martel', serif",
}

const buttons = {
  sm: {
    paddingVertical: "3px",
    paddingHorizontal: "5px",
  },
  nm: {
    paddingVertical: "5px",
    paddingHorizontal: "7px",
  },
  md: {
    paddingVertical: "7px",
    paddingHorizontal: "13px",
  },
  lg: {
    paddingVertical: "17px",
    paddingHorizontal: "27px",
  },
}

const fontSizes = {
  xxxxl: "6rem",
  xxxl: "4rem",
  xxl: "2.68rem",
  xl: "2.34rem",
  lg: "1.72rem",
  md: "1.375rem",
  nm: "1.1rem",
  sm: "0.95rem",
  xs: "0.75rem",
  xxs: "0.6rem",
}

const radius = {
  sm: "3px",
  nm: "5px",
  md: "8px",
  lg: "13px",
  xl: "21px",
  round: "50%",
}

const shadows = {
  xs: `1px 2px 4px hsla(0, 0%, 0%, 0.05), 1px 1px 2px hsla(0,0%, 0%, 0.02)`,
  sm: `1px 5px 10px  hsla(0, 0%, 0%, 0.25), 1px 2px 2px hsla(0,0%, 0%, 0.04)`,
  nm: `2px 10px 15px hsla(0, 0%, 0%, 0.04), 1px 2px 2px hsla(0,0%, 0%,0.02)`,
  md: `0 15px 25px hsla(0, 0%, 0%, 0.10), 0 3px 5px hsla(0,0%, 0%, 0.07)`,
  lg: `5px 20px 35px hsla(0, 0%, 0%, 0.25) , 0 3px 5px hsla(0,0%, 0%, 0.04)`,
  strong: `5px 20px 35px hsla(0, 0%, 0%, 0.45) , 0 3px 5px hsla(0,0%, 0%, 0.07)`,
  new: `7px 7px 0 rgba(0,0,0,0.15)`,
}

const Theme = {
  gutterWidth: `${gutterWidth}rem`,
  outerMargin: `${outerMargin}rem`,
  gutterCompensation: `${(gutterWidth / 2) * -1}rem`,
  halfGutterWidth: `${gutterWidth / 2}rem`,
  breakPoints: breakPoints,
  containers,
  screen: screen,
  colors: {
    ...colors,
    menu: { ...menuColors },
    header: { ...headerColors },
  },
  margins,
  radius,
  weights,
  fonts,
  fontSizes,
  buttons,
  shadows,
}

export default Theme
