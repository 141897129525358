import React, { Fragment } from "react"
import styled from "styled-components"
import { TwitterIcon, MailIcon, InstagramIcon } from "./icons/SocialIcons"
import Theme from "./base/Theme"
import { FluidContainer, Container } from "./base/Container"
import { Title, Subtitle, Span } from "./base/Text"
import Subscribe from "./subscription/Subscribe"
import FooterImage from "./base/FooterImage"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${props => props.theme.margins.xxl} 0;
  padding-top: 80px;
  background: ${props => props.theme.colors.primary900};
`

const InternalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${p => p.theme.margins.lg};
  width: 100%;
`

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: ${p => p.theme.margins.lg};
  &:last-of-type {
    margin-right: 0;
  }
  svg {
    width: 36px;
    height: 36px;
    fill: ${props => props.theme.colors.primary900};
    stroke: ${props => props.theme.colors.primary500};
  }
  &:hover {
    svg {
      /* fill: ${props => props.theme.colors.primary500}; */
      stroke: ${props => props.theme.colors.primary500};
    }
  }
  &:first-of-type {
    svg {
      width: 41px;
      height: 41px;
    }
  }
`

const EmailLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.xxl};
`

const IconContainer = styled.span`
  display: inline-block;
  margin-right: ${props => props.theme.margins.nm};
  transform: translateY(5px);
  svg {
    width: 24px;
    height: 24px;
    stroke: ${props => props.theme.colors.primary500};
  }
`

const TopImage = styled.div`
  width: 100%;
  background: ${props =>
    props.light ? props.theme.colors.white : props.theme.colors.grey200};
  img {
    width: 100%;
  }
`

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: ${p => p.theme.margins.lg};
`

const Footer = ({ light }) => (
  <Fragment>
    <TopImage light={light}>
      <FooterImage />
    </TopImage>
    <Wrapper>
      <FluidContainer>
        <Container>
          <InternalWrapper>
            <Subscribe />
            <SocialWrapper>
              <IconWrapper>
                <a href="https://twitter.com/codecampshq">
                  <TwitterIcon color={Theme.colors.twitter} />
                </a>
              </IconWrapper>

              <IconWrapper>
                <a href="https://www.instagram.com/codecamps/">
                  <InstagramIcon />
                </a>
              </IconWrapper>
            </SocialWrapper>

            <EmailLine>
              <IconContainer>
                <MailIcon />
              </IconContainer>
              <Span size="sm" color="primary500" lineHeight={1}>
                maciej@codecamps.com
              </Span>
            </EmailLine>

            <CompanyInfo>
              <Title size="xxs" color="primary600">
                © 2017 Level Eleven Web Solutions Ltd.
              </Title>

              <Subtitle size="xxs" color="primary600">
                Company Registered No. 10509511
              </Subtitle>
              <Subtitle size="xxs" color="primary600">
                16 Palace Gates Road
              </Subtitle>

              <Subtitle size="xxs" color="primary600">
                London N22 7BN, UK
              </Subtitle>
            </CompanyInfo>
          </InternalWrapper>
        </Container>
      </FluidContainer>
    </Wrapper>
  </Fragment>
)

export default Footer
