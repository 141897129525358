import React, { Fragment, Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"

import Theme from "./base/Theme"
import Header from "./header/Header"
import Footer from "./Footer"
import GlobalStyle from "./globalStyle"
import {
  gaInitialize,
  gaPageChange,
  setCookiesConsent,
  getCookiesConsent,
} from "./google/ga"
import Cookies from "./cookies/Cookies"

const PageWrapper = styled.div`
  position: relative;
`

class Layout extends Component {
  state = {
    consent: false,
  }

  componentDidMount = () => {
    const consent = getCookiesConsent()
    if (consent) {
      this.setState({ consent: true })
    }
    gaInitialize()
  }

  componentDidUpdate = (prevProps, prevState) => {
    // update page for GA
    if (this.state.consent) {
      gaPageChange()
    }
  }

  onAccept = () => {
    setCookiesConsent(true)
    this.setState({ consent: true })
  }

  render() {
    const { pathname } = this.props
    return (
      <ThemeProvider theme={Theme}>
        <PageWrapper>
          <GlobalStyle />
          <StaticQuery
            query={graphql`
              query SiteTitleQuery {
                site {
                  siteMetadata {
                    siteTitle
                  }
                }
              }
            `}
            render={data => (
              <Fragment>
                <Header pathname={pathname} />
                {this.props.children}
                <Footer light={this.props.light} />
                {!this.state.consent && <Cookies onAccept={this.onAccept} />}
              </Fragment>
            )}
          />
        </PageWrapper>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
