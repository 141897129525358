import ReactGA from "react-ga"

export const gaInitialize = () => {
  ReactGA.initialize("UA-8858797-10")
  ReactGA.ga("set", "anonymizeIp", true)
  ReactGA.pageview(window.location.pathname + window.location.search)
}

export const gaPageChange = () => {
  ReactGA.pageview(window.location.pathname)
}

export const getCookiesConsent = () =>
  JSON.parse(localStorage.getItem("codecamps.cookiesConsent"))

export const setCookiesConsent = state => {
  localStorage.setItem("codecamps.cookiesConsent", state)
}
