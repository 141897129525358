import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Navigation = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.margins.lg};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.primary500};
  transition: all 0.15s ease-in-out;
  border-top: 4px solid ${props => props.theme.colors.primary900};
  border-bottom: 4px solid
    ${props =>
      props.active
        ? props.theme.colors.primary500
        : props.theme.colors.primary900};
  &:last-of-type: {
    margin-right: -${props => props.theme.margins.lg};
  }
  a {
    font-size: ${props => props.theme.fontSizes.sm};
    color: ${props => props.theme.colors.primary500};
  }
  &:hover {
    a {
      color: ${props => props.theme.colors.primary300};
    }
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    padding: 0 ${props => props.theme.margins.nm};
  }
`

const Menu = ({ pathname }) => (
  <Navigation>
    <Item>
      <a
        href="https://www.instagram.com/codecamps/"
        target="_blank"
        rel="noopener noreferrer"
        alt="Codecamps on Instagram"
      >
        Galery
      </a>
    </Item>
    <Item active={pathname === "/blog" || pathname === "/blog/"}>
      <Link to="/blog/">Blog</Link>
    </Item>
    <Item active={pathname === "/story" || pathname === "/story/"}>
      <Link to="/story/">Story</Link>
    </Item>
  </Navigation>
)

export default Menu
