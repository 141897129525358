import React from "react"

export const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <title>
      Exported from Streamline App (https://app.streamlineicons.com)
    </title>
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 23.18,6.71l-2.1-0.9l1.1-2.4l-2.56,0.85c-0.815-0.76-1.885-1.188-3-1.2c-2.483,0.005-4.495,2.017-4.5,4.5v1 c-3.54,0.73-6.63-1.2-9.5-4.5c-0.5,2.667,0,4.667,1.5,6l-3.29-0.5c0.262,2.186,2.052,3.871,4.25,4l-2.75,1c1,2,2.82,2.31,5.25,2.5 c-1.986,1.355-4.346,2.054-6.75,2c12.76,5.67,20.25-2.66,20.25-10V8.23L23.18,6.71z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const FacebookIcon = () => (
  <svg
    aria-labelledby="simpleicons-facebook-icon"
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="simpleicons-facebook-icon">Facebook icon</title>
    <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
  </svg>
)

export const MailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <title>
      Exported from Streamline App (https://app.streamlineicons.com)
    </title>
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 22.63,14.87L15,22.5l-3.75,0.75L12,19.5l7.63-7.63c0.825-0.826,2.163-0.827,2.99-0.002 c0.001,0.001,0.002,0.002,0.002,0.002l0.008,0.008C23.428,12.699,23.405,14.027,22.63,14.87z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 11.25,15.75h-9 c-0.828,0-1.5-0.672-1.5-1.5v-12c0-0.828,0.672-1.5,1.5-1.5h18c0.828,0,1.5,0.672,1.5,1.5v6 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 21.411,1.3l-8.144,6.264 c-1.189,0.915-2.845,0.915-4.034,0L1.089,1.3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const InstagramIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="m6.74.75h10.5c3.314 0 6 2.686 6 6v10.5c0 3.314-2.686 6-6 6h-10.5c-3.314 0-6-2.686-6-6v-10.5c0-3.314 2.686-6 6-6z" />
      <path d="m11.99 7.6c2.971 0 5.38 2.409 5.38 5.38s-2.409 5.38-5.38 5.38-5.38-2.409-5.38-5.38 2.409-5.38 5.38-5.38z" />
      <path d="m11.99 10.48c1.381 0 2.5 1.119 2.5 2.5s-1.119 2.5-2.5 2.5-2.5-1.119-2.5-2.5 1.119-2.5 2.5-2.5z" />
      <path d="m.74 10.53h6.46" />
      <path d="m16.79 10.53h6.45" />
      <path d="m18.24 4.25h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5c0-.552.448-1 1-1z" />
      <path d="m5.61.86v3.93" />
      <path d="m8.27.75v4.04" />
      <path d="m10.93.75v4.04" />
    </g>
  </svg>
)
