import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const query = graphql`
  query {
    footer: file(relativePath: { eq: "footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const FooterImage = () => (
  <StaticQuery
    query={query}
    render={data => <Img fluid={data.footer.childImageSharp.fluid} critical />}
  />
)

export default FooterImage
